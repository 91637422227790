import React, { Component } from 'react'
import { withPrefix } from "gatsby"
class ScriptsLoader extends Component {
    componentDidMount(){
        // jquery.min.js
        const jqueryscript=document.createElement('script')
        jqueryscript.src=withPrefix('jquery.min.js')
        jqueryscript.async=false;
        document.body.appendChild(jqueryscript)

        // browser.min.js
        const browserscript=document.createElement('script')
        browserscript.src=withPrefix('browser.min.js')
        browserscript.async=false;
        document.body.appendChild(browserscript)

        // breakpoint.min.js
        const breakpointsscript=document.createElement('script')
        breakpointsscript.src=withPrefix('breakpoints.min.js')
        breakpointsscript.async=false; 
        document.body.appendChild(breakpointsscript)

        // util.js
        const utilscript=document.createElement('script')
        utilscript.src=withPrefix('util.js')
        utilscript.async=false; 
        document.body.appendChild(utilscript)

        // main.js
        const mainscript=document.createElement('script')
        mainscript.src=withPrefix('main.js')
        mainscript.async=false; 
        document.body.appendChild(mainscript)

        if (typeof window !== 'undefined') {
            window.addEventListener('some_event', this.eventHandler)
            let something= window.nameofyourstarterfunction // gets the function reference
        }
    }
    eventHandler=()=>{
        console.log('event was triggered')
    }
    // removes the handler as it's no longer needed.
    componentWillUnmount() {
        if (typeof window !== 'undefined') {
        window.removeEventListener('some_event', this.eventHandler)
        }
    }
    render (){
        return (<div />)
    }
}
export default ScriptsLoader