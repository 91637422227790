/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
// import { Helmet } from 'react-helmet'
import { withPrefix } from "gatsby"
import ScriptsLoader from "./scripts-loader"
import Header from "./header"
import Menu from "./menu"
import "./layout.css"

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
	  query SiteTitleQuery {
		site {
		  siteMetadata {
			title
		  }
		}	  }
	`)
  

  return (
    <>
      <Header />
	  <ScriptsLoader />
      <div
        style={{
          margin: `0 auto`
        }}
      >
        <div id="wrapper">
          <div id="main">
            <div className="inner">
              {children}
            </div>
          </div>
          
					<div id="sidebar">
						<div className="inner">

								{/* <section id="search" class="alt">
									<form method="post" action="#">
										<input type="text" name="query" id="query" placeholder="Search" />
									</form>
									
								</section>  */}

								<nav id="menu">
									<header className="major">
										<a href="/"><img src={withPrefix('barons-logo.png')} alt="" height="50" />Handleiding</a>												
									</header>
									<Menu />									
								</nav>

								{/* <section>
									<header class="major">
										<h2>Ante interdum</h2>
									</header>
									<div class="mini-posts">
										<article>
											<a href="#" class="image"><img src="images/pic07.jpg" alt="" /></a>
											<p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore aliquam.</p>
										</article>
										<article>
											<a href="#" class="image"><img src="images/pic08.jpg" alt="" /></a>
											<p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore aliquam.</p>
										</article>
										<article>
											<a href="#" class="image"><img src="images/pic09.jpg" alt="" /></a>
											<p>Aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore aliquam.</p>
										</article>
									</div>
									<ul class="actions">
										<li><a href="#" class="button">More</a></li>
									</ul>
								</section>

								<section>
									<header class="major">
										<h2>Get in touch</h2>
									</header>
									<p>Sed varius enim lorem ullamcorper dolore aliquam aenean ornare velit lacus, ac varius enim lorem ullamcorper dolore. Proin sed aliquam facilisis ante interdum. Sed nulla amet lorem feugiat tempus aliquam.</p>
									<ul class="contact">
										<li class="icon solid fa-envelope"><a href="#">information@untitled.tld</a></li>
										<li class="icon solid fa-phone">(000) 000-0000</li>
										<li class="icon solid fa-home">1234 Somewhere Road #8254<br />
										Nashville, TN 00000-0000</li>
									</ul>
								</section> */}

								<footer id="footer">
									<p className="copyright">&copy; Barons  {new Date().getFullYear()}. All rights reserved.</p>
								</footer>

						</div>
					</div>

        </div>
        {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}

        {/* <script src="../js/jquery.min.js"></script>
			  <script src="../js/browser.min.js"></script>
			  <script src="../js/breakpoints.min.js"></script>
			  <script src="../js/util.js"></script>
			  <script src="../js/main.js"></script> */}
		 {/* <Helmet>		
		 	<script src={withPrefix('jquery.min.js')} />
			<script src={withPrefix('browser.min.js')} />
			<script src={withPrefix('breakpoints.min.js')} />
			<script src={withPrefix('util.js')} />
			<script src={withPrefix('main.js')} />
		</Helmet> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout