import React from "react"
import { Link } from "gatsby"

const PostLink = ({ post }) => (
  <li>
    <Link to={post.elements["slug"].value }>
      {post.elements["title"].value}
    </Link>
  </li>
)

export default PostLink