import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { isLoggedIn } from "../services/auth"
import PostLink from "./post-link"

const Menu = () => {  
    const data = useStaticQuery(graphql`
    query MyQuery {
      allKontentItemTutorial(sort: {order: ASC, fields: elements___order___value}) {
        edges {
          node {
            elements {
              title {
                value
              }
              slug {
                value
              }
            }
            id
          }
        }
      }
    }
    `)    
    if (!isLoggedIn()) {
      return (
          <ul>
              <li>
                  <a href="/login">Inloggen</a>
              </li>
          </ul>
      )
    }

    return (
        <ul>
            {
                data.allKontentItemTutorial.edges.map(item=>(
                        <PostLink key={item.node.id} post={item.node} />                            
                    ))
            }
        </ul>
    )      
}
  
export default Menu