import React from "react"
import Layout from "../components/layout"
import Login from "../components/login"

const LoginPage = () => (
  <Layout>
      <section id="banner">
            <div className="content"> 
                <Login />
            </div>
      </section>    
  </Layout>
)

export default LoginPage
